import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Checkout
import Checkout from './pages/Checkout';

// Produtos

export default function Rotas() {
    return (
        <div className="App">
            <BrowserRouter>

                {/* Checkout */}
                <Route path="/" exact={true} component={Checkout} />

            </BrowserRouter>
        </div>
    );
}