import React, { useEffect, useState } from 'react';
import Routes from './Router'
import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyCfC3BCFfE-eKB2SKqHhzTZFaltbjAstxk",
  authDomain: "d7producoes-13768.firebaseapp.com",
  projectId: "d7producoes-13768",
  storageBucket: "d7producoes-13768.appspot.com",
  messagingSenderId: "962379988388",
  appId: "1:962379988388:web:7a192cbf00eef0da5ad531"
};
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">

      <Routes />
    </div>
  );
}

export default App;
