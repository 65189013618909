// Carrega Cidades
export const criaPreferencia = (dados) => {
    const URL = 'https://us-central1-fabrica-de-sonhos-virtua-e8cae.cloudfunctions.net/app/geraPix'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method:'POST',
        body:JSON.stringify(dados)
    }).then((response) => response.json());
}

export const buscaPagamento = (pagamento) => {
    const URL = 'https://api.mercadopago.com/v1/payments/'+pagamento
    return fetch(URL, {
        headers: {
            "Authorization": `Bearer APP_USR-6937144551299254-090318-c513153f0bb99af7eaec3d14399a4322-160993167`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method:'GET'
    }).then((response) => response.json());
}