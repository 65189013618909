import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import moment from 'moment';
import { criaPreferencia, buscaPagamento } from '../../services/Checkout'
import { cpf } from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';


export default function Produtos() {

    const [erroCpf, setErroCpf] = useState(false)
    const [erroEmail, setErroEmail] = useState(false)

    const [qrcode, setQrcode] = useState('')
    const [copiaCola, setCopiaCola] = useState('')

    const [valor] = useState(0.14) // Mudar o valor aqui. Se for R$10,00, colocar apenas 10, se for R$10,50 colocar 10.5
    const [inputCpf, setInputCpf] = useState('')
    const [email, setEmail] = useState('')
    const intervalId = useRef(null);


    const startApiCalls = (pagamento) => {
        fetchApiData(pagamento);
        intervalId.current = setInterval(() => {
            fetchApiData(pagamento);
          }, 10000);
    };

    const fetchApiData = async (pagamento) => {
        try {
            buscaPagamento(pagamento).then((res) => {
                if (res.status === 'approved') {
                    // Mudar URL de redirecionamento
                    window.location.href = 'https://pix.d7producoes.com.br/2308-2/';
                    clearInterval(intervalId.current);
                }
            });
        } catch (err) {
            mensagemErro('Erro ao realizar pagamento');
        }
    };

    function geraPagamento() {
        if (!cpf.isValid(inputCpf.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CPF inválido')
            return
        }
        if (!email && !email.includes('@')) {
            mensagemAlerta('Emai inválido')
            return
        }
        const dados = {
            nome: 'Mensalidade',
            cpf: inputCpf.replace(/[^a-z0-9]/gi, ''),
            email: email,
            descricao: 'Mensalidade academia',
            valor: parseFloat(valor),
        }
        mensagemLoading('Gerando pix..')
        criaPreferencia(dados).then((res) => {
            setQrcode(res.data.pagamento.qrcode)
            setCopiaCola(res.data.pagamento.chave)
            Swal.close()
            startApiCalls(res.data.id)

        })

    }

    const copyToClipboard = () => {
        const textArea = document.createElement('textarea');

        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        textArea.value = copiaCola;

        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999); // Para dispositivos móveis

        try {
            document.execCommand('copy');
            mensagemSucesso('Texto copiado! Cole o código em seu aplicativo de pagamento na área Pix');
        } catch (err) {
            console.error('Erro ao copiar texto: ', err);
        }

        document.body.removeChild(textArea);
    };
    const handleCpf = (event) => {
        event.persist();
        setInputCpf(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div>
            <main role="main">
                <div >
                    <img style={{ height: 150, width: 150 }} src={'https://i.pinimg.com/736x/13/bc/a1/13bca1e8629eb3de7e4b5cd5fc18d87b.jpg'} />
                </div>
                <div style={{ backgroundColor: '#ef2e4b', padding: 10, textAlign: 'center' }}>
                    <p style={{ color: 'white', marginTop: 10, fontSize: 15 }}>{'Checkout de pagamento'}</p>
                </div>

                <div className="album py-5 bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">

                                <div className="card mb-4 shadow-sm p-4" style={{ borderRadius: 8 }}>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}>CPF</label>
                                                <InputMask style={{ fontSize: 15, height: 45, borderColor: erroCpf ? 'red' : null }} maskChar={""} mask="999.999.999-99" placeholder='000.000.000-00' onChange={handleCpf} value={inputCpf} name="username" type="text" className="form-control form-control-lg" />
                                                {erroCpf ? <p style={{ fontSize: 10, color: 'red' }}>CPF inválido</p> : null}
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}>Email</label>
                                                <input style={{ fontSize: 15, height: 45, borderColor: erroEmail ? 'red' : null }} onChange={handleEmail} value={email} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="email@exemplo.com" />
                                                {erroEmail ? <p style={{ fontSize: 10, color: 'red' }}>Email inválido</p> : null}
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ textAlign: 'end', marginTop: 30 }}>
                                            <button onClick={() => geraPagamento()} type="button" class="btn btn-primary btn-lg" style={{ fontSize: 14, }}>{'REALIZAR PAGAMENTO'}</button>
                                        </div>
                                    </div>
                                </div>
                                {qrcode ?
                                    <div className="logo text-center">
                                        <img style={{ width: 200, height: 200, textAlign: 'center' }} src={"data:image/png;base64, " + qrcode} alt="Red dot" />
                                        <hr style={{ width: '50%' }} />
                                        <p style={{ fontSize: 13 }}>Se preferir, pague com a opção PIX Copia e Cola:</p>
                                        <div style={{ width: '100%', alignItems: 'center' }}>
                                            <input value={copiaCola} style={{ fontSize: 13 }} type="text" className="form-control" />
                                            <button onClick={() => copyToClipboard()} style={{ width: '100%', backgroundColor: '#58ACFA', borderRadius: 8, marginTop: 20, fontSize: 13, fontWeight: 'bold', color: 'white' }} className="btn btn-main">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                                </svg> COPIAR CÓDIGO
                                            </button>
                                            <button onClick={() => copyToClipboard()} style={{ width: '100%', backgroundColor: 'green', borderRadius: 8, marginTop: 10, fontSize: 13, fontWeight: 'bold', color: 'white' }} className="btn btn-main">
                                                Aguardando pagamento....
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                            </div>
                            <div className="col-md-1">

                            </div>
                            <div className="col-md-4">
                                <div className="card mb-4 shadow-sm p-3" style={{ borderRadius: 8 }}>
                                    <h5>Seu carrinho</h5>
                                    <div className='row' style={{ marginTop: 20 }}>
                                        <div className='col-md-7'>
                                            <h6 style={{ fontSize: 14 }}>{'Produto de roupa'}</h6>
                                            <p style={{ fontSize: 13, color: '#A4A4A4' }}>PROMOÇÃO APLICADA</p>
                                        </div>

                                    </div>
                                    <hr />
                                    <div className='row' >
                                        <div className='col-md-8'>
                                            <p>Total</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <p>R$ {valor}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="text-muted">
                <div className="container" style={{ textAlign: 'center', padding: 16 }}>
                    <h6 style={{ fontSize: 14 }}>Formas de pagamento </h6>
                    <img style={{ height: 50, width: 50 }} src='https://pay.growth-resgatar.com/assets/img/card-pix.svg' />

                    <h6 style={{ fontSize: 14, marginTop: 20 }}>© {moment().format('YYYY')} Todos os direitos reservados.</h6>
                </div>
            </footer>
        </div>

    );
}